/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-06-30",
    versionChannel: "nightly",
    buildDate: "2023-06-30T00:19:35.533Z",
    commitHash: "1c151a86d6d3ecc4a4e0d41aeee2b7b1c878f170",
};
